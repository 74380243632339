// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api';
import BaseRepositoryError from '@/repositories/base.errors';
import { camelToSnake } from '@/utils/string';

const UserRepository = {
  /*
   * getUserInfo get information
   *
   * @returns obj {
   * username,
   * email,
   * first_name,
   * last_name
   * }
   * @throws BaseRepositoryError
  */
  getUserInfo: async () => {
    const requestData = {
      method: 'get',
      url: '/rest-auth/user/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getUserCacheInfo: async (pk) => {
    const requestData = {
      method: 'get',
      url: `/user-cache/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  setUserCacheInfo: async ({ pk, currency }) => {
    const requestData = {
      method: 'post',
      url: `/user-cache/${pk}/set_currency/`,
      data: {
        currency,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  resetPasswordChange: async (uid, token, password1, password2) => {
    const requestData = {
      method: 'post',
      url: '/password-reset/change/',
      data: {
        uid,
        token,
        new_password1: password1,
        new_password2: password2,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data.detail;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  setOwnPassword: async (payloads) => {
    /*
     * setPassword
     * @returns result
     * @throws BaseRepositoryError
   */
    const requestData = {
      method: 'post',
      url: '/rest-auth/password/change/',
      data: {
        old_password: payloads.oldPassword,
        new_password1: payloads.password1,
        new_password2: payloads.password2,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  editOwnUser: async (payloads) => {
    /*
     * editJsonEmployee - update data of the employee
     * @returns employee object
     * @throws BaseRepositoryError
   */
    console.log('payloads', payloads);
    const allowedFields = [
      'email', 'firstName', 'lastName', 'cellPhone', 'phone', 'avatar',
      'dateOfBirth', 'dateStartWorking', 'linkedinUrl',
    ];
    const data = new FormData();
    Object.keys(payloads).forEach((key) => {
      if (!allowedFields.includes(key)) {
        // pass not allowed fields
      } else if (payloads[key] === 'boolean') {
        data.append(camelToSnake(key), payloads[key]);
      } else if (key === 'companyIds') {
        payloads[key].forEach((el) => {
          data.append(camelToSnake(key), el);
        });
      } else {
        data.append(camelToSnake(key), payloads[key]);
      }
    });
    console.log(data);
    const requestData = {
      method: 'put',
      url: '/rest-auth/user/',
      data,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default UserRepository;

export { UserRepository, BaseRepositoryError };
