// eslint-disable-next-line no-unused-vars
import { actionTypes, mutationTypes } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import { UserRepository, BaseRepositoryError } from '@/repositories/auth.user';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

const state = {
  accessToken: null,
  isAuthenticated: false,
  authError: null,
  refreshTokenPromise: null,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  authError: (state) => state.authError,
};

const mutations = {
  [mutationTypes.LOGIN_SUCCESS](state, accessToken) {
    state.accessToken = accessToken;
    state.isAuthenticated = true;
  },
  [mutationTypes.AUTH_ERROR](state, errorMsg) {
    state.authError = errorMsg;
  },
  [mutationTypes.LOGOUT_SUCCESS](state) {
    state.accessToken = null;
    state.isAuthenticated = null;
  },
  [mutationTypes.REFRESH_TOKEN_PROMISE](state, refreshTokenPromise) {
    state.refreshTokenPromise = refreshTokenPromise;
  },
};

const actions = {
  async [actionTypes.LOGIN_USER]({ commit }, payloads) {
    try {
      const accessToken = await UserRepository.login(payloads.email, payloads.password);
      commit(mutationTypes.LOGIN_SUCCESS, accessToken);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.AUTH_ERROR, e.message);
      }
      return false;
    }
  },

  [actionTypes.REFRESH_TOKEN]({ commit, state }) {
    // If this is the first time the refreshToken has been called, make a request
    // otherwise return the same promise to the caller
    if (!state.refreshTokenPromise) {
      const p = UserRepository.refreshToken();
      commit(mutationTypes.REFRESH_TOKEN_PROMISE, p);
      // Waiting for the UserService.refreshToken() to resolve.
      // On success set the token and clear promise
      // Clear the promise on error as well.
      p.then(
        (accessToken) => {
          commit(mutationTypes.REFRESH_TOKEN_PROMISE, null);
          commit(mutationTypes.LOGIN_SUCCESS, accessToken);
        },
        () => {
          commit(mutationTypes.REFRESH_TOKEN_PROMISE, null);
        },
      );
    }

    return state.refreshTokenPromise;
  },

  [actionTypes.LOGOUT_USER]({ commit }) {
    UserRepository.logout();
    commit(mutationTypes.LOGOUT_SUCCESS);
    // router.go();
    router.push({ name: 'login' });
  },

  async [actionTypes.RESET_PASSWORD_SEND]({ commit }, payloads) {
    try {
      await UserRepository.resetPasswordSend(payloads.email);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.AUTH_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.RESET_PASSWORD_CHANGE]({ commit }, payloads) {
    try {
      await UserRepository.resetPasswordChange(payloads.uid, payloads.token,
        payloads.password1, payloads.password2);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.AUTH_ERROR, e.message);
      }
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
