// eslint-disable-next-line import/no-cycle
import { actionTypes, mutationTypes } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import { ReportDataRepository, BaseRepositoryError } from '@/repositories/finance.data';
import { REPORT_TYPE } from '@/configs/';
import moment from 'moment';

const defaultReportData = {
  grouped: [], indicators: [], data: null, report_data: {},
};

const state = {
  reportDataErrors: null,
  reportDataStatistic: null,
  reportData: defaultReportData,
  reportDataDates: [],
  createReportData: null,
  createdReports: [],
};

const getters = {
  reportDataStatistic: (state) => state.reportDataStatistic,
  reportData: (state) => state.reportData,
  reportDates: (state) => state.reportData.grouped.reverse(),
  reportCeoNotes: (state) => {
    if (state.reportData?.data) {
      return Object.entries(state.reportData?.data)?.reduce((acc, [key, value]) => {
        acc.push({
          date: key,
          notes: value,
        });
        return acc;
      }, []);
    }
    return [];
  },
  reportCurrencies: (state) => {
    if (!state.reportData) {
      return [];
    }
    return Object.values(state.reportData.grouped_dates).reduce((acc, curr) => {
      const currList = curr.list || [];
      return [...acc, ...currList];
    }, []);
  },
  reportDataCompanyCurrency: (state) => {
    if (!state.reportData) {
      return '';
    }
    return state.reportData.report_data.company_currency;
  },
  reportGroupedDates: (state) => {
    if (!state.reportData.grouped_dates) {
      return {};
    }
    return state.reportData.grouped_dates;
  },
  reportTableData: (state) => state.reportData,
  reportTableDataIndicators: (state) => {
    const categories = {
      operation_expenses: {
        name: 'Operation expenses:',
        used: 0,
      },
      other_income: {
        name: 'Other Income:',
        used: 0,
      },
      other_expenditure: {
        name: 'Other Expenditure:',
        used: 0,
      },
      current_asset: {
        name: 'Current Assets:',
        used: 0,
      },
      current_liabilities: {
        name: 'Current Liabilities:',
        used: 0,
      },
      equity: {
        name: 'Equity:',
        used: 0,
      },
    };
    /*
      category:"sale"
      name:"Sales"
      order_index:20
      type: 2
      value:"sales"
    */
    return state.reportData.indicators.reduce((acc, indicator) => {
      // eslint-disable-next-line no-unsafe-negation
      if (indicator.category in categories) {
        if (!categories[indicator.category].used) {
          acc.push({
            category: indicator.category,
            name: categories[indicator.category].name,
            order_index: indicator.order_index,
            type: REPORT_TYPE.HEADER,
            value: indicator.value,
          });
          categories[indicator.category].used = 1;
        }
      }
      acc.push(indicator);
      return acc;
    }, []);
  },
  // reportTableDataArrIndicators: (state)
  reportExportData: (state) => (
    // eslint-disable-next-line no-unused-vars
    state.reportData.indicators.reduce((acc, indicator, _, arr) => {
      const objItem = {
        Indicator: indicator.name,
      };
      state.reportData.grouped.forEach((date) => {
        objItem[date] = state.reportData.data[date][indicator.value];
      });
      acc.push(objItem);
      return acc;
    }, [])),
  reportExportCashData: (state) => (
    // eslint-disable-next-line no-unused-vars
    state.reportData.indicators.reduce((acc, indicator, _, arr) => {
      const objItem = {
        Indicator: indicator.name,
        Institution: indicator.institution,
        Holding: indicator.holding,
        Currency: indicator.currency,
      };
      state.reportData.grouped.forEach((date) => {
        if (state.reportData.data[date][indicator.value]) {
          objItem[date] = state.reportData.data[date][indicator.value].value
            ? state.reportData.data[date][indicator.value].value : 0;
          objItem[`USD-${date}`] = state.reportData.data[date][indicator.value].value_usd
            ? state.reportData.data[date][indicator.value].value_usd : 0;
        } else {
          objItem[date] = 0;
          objItem[`USD-${date}`] = 0;
        }
      });
      acc.push(objItem);
      return acc;
    }, [])),
  // eslint-disable-next-line no-unused-vars
  reportFilterQuarters: (state) => state.reportDataDates.reduce((acc, date, _, arr) => {
    const key = `Q${moment(date).quarter()} ${moment(date).year()}`;
    if (acc[key] && 'dates' in acc[key]) {
      acc[key].dates.push(date);
    } else {
      acc[key] = { dates: [date] };
    }
    return acc;
  }, {}),
  // eslint-disable-next-line no-unused-vars
  reportFilterAnnual: (state) => state.reportDataDates.reduce((acc, date, _, arr) => {
    const key = `${moment(date).year()}`;
    if (acc[key] && 'dates' in acc[key]) {
      acc[key].dates.push(date);
    } else {
      acc[key] = { dates: [date] };
    }
    return acc;
  }, {}),
  // eslint-disable-next-line no-unused-vars
  reportFilterDate: (state) => state.reportDataDates.reduce((acc, date, _, arr) => {
    const key = `${moment(date).format('MMM YYYY')}`;
    if (acc[key] && 'dates' in acc[key]) {
      acc[key].dates.push(date);
    } else {
      acc[key] = { dates: [date] };
    }
    return acc;
  }, {}),
  REPORT_TYPE: () => REPORT_TYPE,
};

const mutations = {
  [mutationTypes.REPORT_DATA_ERRORS](state, message) {
    state.reportDataErrors = message;
  },
  [mutationTypes.REPORT_DATA_STATISTIC](state, reportDataStatistic) {
    state.reportDataStatistic = reportDataStatistic;
  },
  [mutationTypes.REPORT_DATA](state, reportData) {
    state.reportData = reportData;
  },
  [mutationTypes.CLEAR_REPORT_DATA](state) {
    state.reportData = defaultReportData;
  },
  [mutationTypes.REPORT_DATA_DATES](state, reportDataDates) {
    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line arrow-body-style
    const arrayDate = reportDataDates.dates.map((el) => el.date);
    state.reportDataDates = arrayDate.filter((value, i, arr) => arr.indexOf(value) === i);
  },
  [mutationTypes.CREATED_REPORTS](stare, createdReports) {
    state.createdReports = createdReports;
  },
};

const actions = {
  async [actionTypes.GET_REPORT_DATA_STATISTIC]({ commit }, payloads) {
    let reportDataStatistic;
    try {
      if (payloads.isTotal) {
        reportDataStatistic = await ReportDataRepository.getReportDataTotalStatistic();
      } else {
        reportDataStatistic = await ReportDataRepository.getReportDataStatistic(payloads.companyId);
      }
      commit(mutationTypes.REPORT_DATA_STATISTIC, reportDataStatistic);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_DATA_ERRORS, e.message);
      }
      return false;
    }
  },
  async [actionTypes.GET_REPORT_DATA_DATES]({ commit }, payloads) {
    try {
      const reportDataDates = await ReportDataRepository.getReportDataDates(payloads.companyId);
      commit(mutationTypes.REPORT_DATA_DATES, reportDataDates);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_DATA_ERRORS, e.message);
      }
      return false;
    }
  },
  async [actionTypes.GET_REPORT_DATA]({ commit }, payloads) {
    try {
      const reportData = await ReportDataRepository.getReportData(payloads.companyId, {
        reportType: payloads.reportType,
        dateFrom: payloads.dateFrom,
        dateTo: payloads.dateTo,
        monthsNbm: payloads.monthsNbm,
        reportId: payloads.reportId,
        groupBy: payloads.groupBy,
      });
      commit(mutationTypes.REPORT_DATA, reportData);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_DATA_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.CREATE_REPORT]({ commit }, payloads) {
    try {
      return [await ReportDataRepository.createReportData(payloads), true];
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_DATA_ERRORS, e.message);
      }
      return [[], false];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
