import axios from 'axios';
import { actionTypes } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import TokenService from '../storage/token';

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  interceptor_401: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  mount401Interceptor() {
    this.interceptor_401 = axios.interceptors.response.use((response) => response,
      async (error) => {
        if (error.request.status === 401) {
          if (error.config.url.includes('/api-token-refresh/')) {
            // Refresh token has failed. Logout the user
            store.dispatch(actionTypes.LOGOUT_USER);
            throw error;
          } else {
            // Refresh the access token
            try {
              // await store.dispatch(actionTypes.REFRESH_TOKEN);
              // Retry the original request
              return this.customRequest({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data,
              });
            } catch (e) {
              // Refresh has failed - reject the original request
              throw error;
            }
          }
        }
        // If error was not 401 just reject as is
        throw error;
      });
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this.interceptor_401);
  },

  setAuthHeader() {
    axios.defaults.headers.common.Authorization = `JWT ${TokenService.getToken()}`;
  },

  setContentType(type) {
    axios.defaults.headers.contentType = type;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  /*
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
  */
  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;
