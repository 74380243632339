// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api';
import BaseRepositoryError from '@/repositories/base.errors';

const CurrenciesRepository = {
  getCurrenciesList: async () => {
    const requestData = {
      method: 'get',
      url: '/currencies/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getCurrenciesRates: async (payloads) => {
    const requestData = {
      method: 'get',
      url: '/currencies-rates/',
      params: {
        ...payloads.selectedCurrencyId && { currency_to: payloads.selectedCurrencyId },
        ...payloads.dates && { dates: payloads.dates },
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getCurrenciesDailyRates: async () => {
    const requestData = {
      method: 'get',
      url: '/currencies-daily-rates/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default CurrenciesRepository;
export { CurrenciesRepository, BaseRepositoryError };
