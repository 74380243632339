// import Vue from 'vue';
import { actionTypes, mutationTypes } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import { UserRepository, BaseRepositoryError } from '@/repositories/user.user';
// eslint-disable-next-line import/no-cycle

import { formatProfile, formatEditedProfile } from '../formatters';
import { USER_TYPES_URL_PREFIX } from '../../configs';

import { defineAbilityFor } from '../../casl';

const state = {
  userInfo: {
    pk: null,
    fullName: null,
    firstName: null,
    lastName: null,
    avatar: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
    phone: null,
    // about: null,
    email: null,
    company: null,
    companyData: {},
    companyId: null,
    userCompanyId: null,
    // skills: null,
    dateStartWorking: null,
    dateOfBirth: null,
    isPermissionAccessUsers: null,
    isPermissionEditUsers: null,
    isActive: null,
    userType: null,
    newslettersUserType: null,
    isAdmin: false,
  },
  userError: null,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  userError: (state) => state.userError,
  userInfo: (state) => state.userInfo,
  userEdit: (state) => formatEditedProfile(state.userInfo),
  routerPrefix: (state) => {
    if (state.userInfo.userType) {
      return USER_TYPES_URL_PREFIX[state.userInfo.userType];
    }
    return '';
  },
};

const mutations = {
  [mutationTypes.USER_INFO](state, userInfo) {
    state.userInfo = formatProfile(userInfo);
    defineAbilityFor(state.userInfo);
  },
  [mutationTypes.USER_ERROR](state, error) {
    state.userError = error;
  },
};

const actions = {
  async [actionTypes.GET_USER_INFO]({ commit, dispatch }) {
    try {
      const userInfo = await UserRepository.getUserInfo();
      commit(mutationTypes.USER_INFO, userInfo);
      await dispatch(actionTypes.GET_USER_CACHE_INFO, {
        pk: userInfo.pk,
      });
      // get currency
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.USER_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_USER_CACHE_INFO]({ commit }, { pk }) {
    try {
      const userCacheInfo = await UserRepository.getUserCacheInfo(pk);
      commit(mutationTypes.USER_CACHE_INFO, userCacheInfo);
      // get currency
      commit(
        `financeReports/${mutationTypes.SELECTED_CURRENCY}`,
        { ...userCacheInfo?.currency_details } || { ticker: 'USD' }, { root: true },
      );
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.USER_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.SET_USER_CACHE_INFO]({ commit }, { pk, currency }) {
    try {
      const userCacheInfo = await UserRepository.setUserCacheInfo({ pk, currency });
      commit(mutationTypes.USER_CACHE_INFO, userCacheInfo);
      // get currency
      commit(
        `financeReports/${mutationTypes.SELECTED_CURRENCY}`,
        { ...userCacheInfo?.currency_details } || { ticker: 'USD' }, { root: true },
      );
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.USER_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.EDIT_OWN_USER]({ commit }, payloads) {
    try {
      const userInfo = await UserRepository.editOwnUser(payloads);
      commit(mutationTypes.USER_INFO, userInfo);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.USER_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.SET_OWN_PASSWORD]({ commit }, payloads) {
    try {
      await UserRepository.setOwnPassword(payloads);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
