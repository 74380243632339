const host = process.env.NODE_ENV === 'production' ? 'insideggg.com' : 'dev.insideggg.com';

export const webConfig = {
  baseURL: process.env.NODE_ENV === 'production' ? `https://${host}` : `https://${host}`,
};

export const apiUrl = {
  baseURL: `${webConfig.baseURL}/api/v1`,
};

export const COMPANY_USER = 1;
export const EMPLOYEE = 2;
export const WEB_ADMIN = 3;

export const USER_TYPES_URL_NAME = {
  [COMPANY_USER]: 'company-cabinet',
  [EMPLOYEE]: 'employee-cabinet',
  [WEB_ADMIN]: 'admin-cabinet',
};

export const USER_TYPES_URL_PREFIX = {
  [COMPANY_USER]: 'company',
  [EMPLOYEE]: 'employee',
  [WEB_ADMIN]: 'admin',
};

export const USER_TYPES = [
  { value: EMPLOYEE, label: 'Employee' },
  { value: COMPANY_USER, label: 'Company User' },
];

export const NEWS_NO_ACCESS = 1;
export const NEWS_READ_ONLY = 2;
export const NEWS_CREATOR = 3;
export const NEWS_PUBLISHER = 4;

export const NEWSLETTERS_USER_TYPES = [
  // { value: NEWS_NO_ACCESS, label: 'No Access' },
  { value: NEWS_READ_ONLY, label: 'Read Only' },
  { value: NEWS_CREATOR, label: 'Creator' },
  { value: NEWS_PUBLISHER, label: 'Publisher' },
];

export const VISIBILITY_NO_ACCESS = 1;
export const VISIBILITY_COMPANY = 2;
export const VISIBILITY_GLOBAL = 3;
export const VISIBILITY_ADMIN = 4;

export const NEWS_VISIBILITY = [
  { value: VISIBILITY_NO_ACCESS, label: 'No Access' },
  { value: VISIBILITY_COMPANY, label: 'View Company Only' },
  { value: VISIBILITY_GLOBAL, label: 'View Global' },
];

export const USER_VISIBILITY = [
  { value: VISIBILITY_COMPANY, label: 'View Company Only' },
  { value: VISIBILITY_GLOBAL, label: 'View Global' },
  { value: VISIBILITY_ADMIN, label: 'Administrator' },
];

export const balanceSheetID = '2';
export const keyPerformanceIndicatorId = '3';
// report indicator types
export const REPORT_TYPE = {
  CALCULATED: 1,
  INPUT_BY_USER: 2,
  PULLED_FROM_REPORT: 3,
  HEADER: 4,
};

// GENERAL TYPES OF REPORTS
export const REPORT_REGULAR = 1;
export const REPORT_GLOBAL_CASH = 2;
export const CEO_REPORT = 3;

export const MAX_SIZE_NEW_IMG = 10;
export const MAX_SIZE_USER_IMG = 10;

export const SEPTEMBER = '09-30';
export const DECEMBER = '12-31';
export const ENDS_OF_YEARS = {
  SEPTEMBER,
  DECEMBER,
};

export const CURRENCY_ICONS = {
  JPY: '¥',
  USD: '$',
  GBP: '£',
  EUR: '€',
};

export const CURRENCY_TICKERS = {
  USD: 'USD',
  GBP: 'GBP',
  JPY: 'JPY',
  EUR: 'EUR',
  CHF: 'CHF',
};

export const GROUP_QUARTERLY = 'q';
export const GROUP_MONTHLY = 'm';
export const GROUP_ANNUAL = 'y';
export const GROUP_YEAR_BY_YEAR = 'yby';

export const COMPANY_REPORT_ALLOWED = 1;
export const COMPANY_REPORT_NOT_ALLOWED = 2;

export const REGULAR_REPORT = 1;
export const CASH_POSITION_REPORT = 2;

export const CUSTOM_REDACTOR_DISABLED_IMG = [[{
  header: [false, 1, 2, 3, 4, 5, 6],
}], ['bold', 'italic', 'underline', 'strike'], // toggled buttons
[{
  align: '',
}, {
  align: 'center',
}, {
  align: 'right',
}, {
  align: 'justify',
}], ['blockquote', 'code-block'], [{
  list: 'ordered',
}, {
  list: 'bullet',
}, {
  list: 'check',
}], [{
  indent: '-1',
}, {
  indent: '+1',
}], // outdent/indent
[{
  color: [],
}, {
  background: [],
}], // dropdown with defaults from theme
['link', 'video'], ['clean'], ['image'],
// remove formatting button removed "image" cause need endpoint
];

export const CUSTOM_REDACTOR_DISABLED_MEDIA = [[{
  header: [false, 1, 2, 3, 4, 5, 6],
}], ['bold', 'italic', 'underline', 'strike'], // toggled buttons
[{
  align: '',
}, {
  align: 'center',
}, {
  align: 'right',
}, {
  align: 'justify',
}], ['blockquote', 'code-block'], [{
  list: 'ordered',
}, {
  list: 'bullet',
}, {
  list: 'check',
}], [{
  indent: '-1',
}, {
  indent: '+1',
}], // outdent/indent
[{
  color: [],
}, {
  background: [],
}], // dropdown with defaults from theme

];
