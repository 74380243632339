// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api';
import BaseRepositoryError from '@/repositories/base.errors';
import { CASH_POSITION_REPORT, CEO_REPORT } from '../configs';

const ReportDataRepository = {

  async getReportDataDates(companyId) {
    /*
     * getReportDataStatistic
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: `/company-reports-data/${companyId}/dates/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getReportDataStatistic(companyId) {
    /*
     * getReportDataStatistic
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: `/company-reports-data/${companyId}/statistics/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getReportDataTotalStatistic() {
    /*
     * getReportDataTotalStatistic
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/company-reports-data/total_statistics/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getReportData(companyId, payloads) {
    /*
     * getReportDataStatistic
     * @returns object
     * @throws BaseRepositoryError
    */
    let url = `/company-reports-data/${companyId}/report_data/`;
    if (payloads.reportType === CASH_POSITION_REPORT) {
      url = `/company-reports-data/${companyId}/report_cash_data/`;
    }
    if (payloads.reportType === CEO_REPORT) {
      url = `/company-reports-data/${companyId}/report_ceo_notes_data/`;
    }
    const requestData = {
      method: 'get',
      url,
      params: {
        // ...payloads.monthsNbm && { months_nmb: payloads.monthsNbm },
        ...payloads.reportType && { report_type: payloads.reportType },
        ...payloads.reportId && { report_id: payloads.reportId },
        ...payloads.dateFrom && { date_start: payloads.dateFrom },
        ...payloads.dateTo && { date_end: payloads.dateTo },
        ...payloads.groupBy && { group_by: payloads.groupBy },
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async createReportData(payloads) {
    /*
     * getReportDataStatistic
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'post',
      url: '/company-reports-data/generate_report/',
      data: {
        ...payloads.companyIdList && { company_id_list: payloads.companyIdList },
        ...payloads.reportDate && { report_date: payloads.reportDate },
        ...payloads.reportType && { report_type: payloads.reportType },
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default ReportDataRepository;
export { ReportDataRepository, BaseRepositoryError };
