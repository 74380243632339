// eslint-disable-next-line import/no-cycle
import { actionTypes, mutationTypes } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import { BaseRepositoryError, ReportRepository } from '@/repositories/finance.reports';
// eslint-disable-next-line import/no-cycle
import { CurrenciesRepository } from '@/repositories/currencies';
import {
  REPORT_GLOBAL_CASH,
  CEO_REPORT,
  REPORT_TYPE,
  CURRENCY_TICKERS,
} from '@/configs/';
import { Decimal } from 'decimal.js';

const defaultReportPage = {
  count: 1,
  page_size: 11,
};

const state = {
  currencies: [],
  selectedCurrency: null,
  reportErrors: null,
  reportTypes: [],
  reportList: [],
  reportItem: {},
  reportCashItem: {},
  reportPage: defaultReportPage,
  reportLogs: {},
  currenciesRates: [],
  currenciesReportRates: [],
  currenciesDailyRates: [],
  companiesSettings: [],
  companySetting: null,
  countries: [],
  institutions: [],
  holdings: [],
};

const getters = {
  currencies: (state) => state.currencies,
  currenciesObj: (state) => (
    state.currencies.reduce((acc, cur) => {
      acc[cur.id] = {
        id: cur.id,
        name: cur.name,
      };
      return acc;
    }, {})
  ),

  currenciesDailyRates: (state) => state.currenciesDailyRates,
  // eslint-disable-next-line no-unused-vars

  currenciesRatesObj: (state) => (state.currenciesRates.reduce((acc, obj) => {
    acc[obj.date] = new Decimal(obj.rate);
    return acc;
  }, {})),

  currenciesReportRatesObj: (state) => (state.currenciesReportRates.reduce((acc, obj) => {
    acc[obj.date] = new Decimal(obj.rate);
    return acc;
  }, {})),

  currenciesLastRates: (state, getters, rootState) => {
    if (!rootState.financeData.reportData) return 1;

    const { currenciesRatesObj, currenciesReportRatesObj } = getters;

    return Object.values(rootState.financeData.reportData.grouped_dates).reduce((acc, obj) => {
      const rate = currenciesRatesObj[obj.last] || 1;
      const rateReport = currenciesReportRatesObj[obj.last] || 1;
      acc[obj.last] = rate / rateReport;
      return acc;
    }, {});
  },

  currenciesAvgRates: (state, getters, rootState) => {
    if (!rootState.financeData.reportData) return 1;

    const { currenciesRatesObj, currenciesReportRatesObj } = getters;

    const avgRate = (ratesObj, obj) => {
      // eslint-disable-next-line arrow-body-style
      const [rateSum, rateCount] = obj.list.reduce((acc, dateKey) => {
        return [acc[0].plus(ratesObj[dateKey]), acc[1].plus(1)];
      }, [new Decimal(0), new Decimal(0)]);
      return rateSum.dividedBy(rateCount) || 1;
      // return 1;
    };

    return Object.values(rootState.financeData.reportData.grouped_dates).reduce((acc, obj) => {
      let rate = 1;
      let rateReport = 1;

      if (currenciesRatesObj[obj.last]) {
        rate = avgRate(currenciesRatesObj, obj);
      }
      if (currenciesReportRatesObj[obj.last]) {
        rateReport = avgRate(currenciesReportRatesObj, obj);
      }
      // 1 / 107
      acc[obj.last] = rate / rateReport;
      return acc;
    }, {});
  },

  selectedCurrency: (state) => state.selectedCurrency,
  reportTypesArr: (state) => state.reportTypes,
  reportTypesObj: (state) => (
    state.reportTypes.reduce((acc, cur) => {
      acc[cur.id] = {
        name: cur.name,
        slug: cur.slug,
        reportType: cur.report_type,
      };
      return acc;
    }, {})
  ),
  isReportType: (state) => (Boolean(state.reportTypes.length)),
  reportErrors: (state) => state.reportErrors,
  reportList: (state) => state.reportList,
  reportPage: (state) => state.reportPage,
  reportItem: (state) => state.reportItem,
  reportLogs: (state) => (state.reportLogs.change_logs || []),
  reportCashItem: (state) => state.reportCashItem,
  reportCashIndicatorObj: (state) => {
    if (state.reportCashItem.indicators) {
      return state.reportCashItem.indicators.reduce((acc, curr) => {
        if (curr.country) {
          if (!acc[curr.country]) {
            acc[curr.country] = [];
          }
          acc[curr.country].push({
            currency: curr.currency,
            country: curr.country,
            holding: curr.holding,
            institution: curr.institution,
            value: curr.value,
            id: curr.id,
          });
        }
        return acc;
      }, {});
    }
    return {};
  },
  reportIndicators: (state) => {
    // filter by "Input User"
    if (!state.reportItem || !state.reportItem.company_report_indicators) return {};
    // return state.reportItem.company_report_indicators;
    return state.reportItem.company_report_indicators.filter((el) => (
      el.indicator.type === REPORT_TYPE.INPUT_BY_USER
      || el.indicator.type === REPORT_TYPE.PULLED_FROM_REPORT
    ));
  },
  reportObjIndicators: (state) => {
    // filter by "Input User" and Pulled from report
    if (!state.reportItem || !state.reportItem.company_report_indicators) return {};
    return state.reportItem.company_report_indicators.reduce((acc, cur) => {
      if (cur.indicator && (cur.indicator.type === REPORT_TYPE.INPUT_BY_USER
        || cur.indicator.type === REPORT_TYPE.PULLED_FROM_REPORT)) {
        // eslint-disable-next-line no-unsafe-negation
        if (!([cur.indicator.category] in acc)) {
          acc[cur.indicator.category] = [];
        }
        const currObj = {
          indicator_type: cur.indicator.type,
          category: cur.indicator.category,
          id: cur.indicator.slug,
          name: cur.indicator.name,
          order_index: cur.indicator.order_index,
          value: new Decimal(cur.value).toFixed(2),
        };
        acc[cur.indicator.category] = [...acc[cur.indicator.category], currObj];
      }
      return acc;
    }, {});
  },
  reportIndicatorsFormObj: (state) => {
    // filter by "Input User" and Pulled from report
    if (!state.reportItem || !state.reportItem.company_report_indicators) return {};
    return state.reportItem.company_report_indicators.reduce((acc, cur) => {
      if (cur.indicator && (cur.indicator.type === REPORT_TYPE.INPUT_BY_USER
        || cur.indicator.type === REPORT_TYPE.PULLED_FROM_REPORT)) {
        acc[cur.indicator.slug] = new Decimal(cur.value).toFixed(2);
      }
      return acc;
    }, {});
  },
  companiesSettings: (state) => state.companiesSettings,
  companySetting: (state) => state.companySetting,
  countries: (state) => state.countries,
  institutions: (state) => state.institutions,
  holdings: (state) => state.holdings,
};

const mutations = {
  [mutationTypes.REPORT_ERRORS](state, message) {
    state.reportErrors = message;
  },
  [mutationTypes.REPORT_TYPES](state, reportTypes) {
    state.reportTypes = reportTypes;
  },
  [mutationTypes.REPORT_LIST](state, payloads) {
    state.reportList = payloads.results;
    state.reportPage = {
      count: payloads.count,
      page_size: payloads.page_size,
    };
  },
  [mutationTypes.CLEAR_REPORT_LIST](state) {
    state.reportList = [];
    state.reportPage = defaultReportPage;
  },
  [mutationTypes.REPORT_ITEM](state, reportItem) {
    state.reportItem = reportItem;
  },
  [mutationTypes.REPORT_CASH_ITEM](state, reportCashItem) {
    state.reportCashItem = reportCashItem;
  },
  [mutationTypes.CLEAR_REPORT_ITEM](state) {
    state.reportCashItem = {};
    state.reportItem = {};
    state.reportLogs = [];
  },
  [mutationTypes.REPORT_CHANGE_LOGS](state, reportLogs) {
    state.reportLogs = reportLogs;
  },
  [mutationTypes.COMPANIES_SETTINGS](state, companiesSettings) {
    state.companiesSettings = companiesSettings;
  },
  [mutationTypes.COMPANY_SETTING](state, companySetting) {
    state.companySetting = companySetting;
  },

  [mutationTypes.CURRENCIES_LIST](state, currencies) {
    state.currencies = currencies;
    // eslint-disable-next-line prefer-destructuring
    if (!state.selectedCurrency) {
      // eslint-disable-next-line prefer-destructuring
      state.selectedCurrency = currencies.find((curr) => (curr.ticker === CURRENCY_TICKERS.USD));
    }
  },
  [mutationTypes.SELECTED_CURRENCY](state, selectedCurrency) {
    state.selectedCurrency = selectedCurrency;
  },

  [mutationTypes.CURRENCIES_RATES](state, currenciesRates) {
    state.currenciesRates = currenciesRates;
  },

  [mutationTypes.CURRENCIES_DAILY_RATES](state, currenciesRates) {
    state.currenciesDailyRates = currenciesRates;
  },

  [mutationTypes.CURRENCIES_REPORT_RATES](state, currenciesRates) {
    state.currenciesReportRates = currenciesRates;
  },

  [mutationTypes.COUNTRY_LIST](state, countries) {
    state.countries = countries;
  },
  [mutationTypes.INSTITUTION_LIST](state, institutions) {
    state.institutions = institutions;
  },
  [mutationTypes.HOLDING_LIST](state, holdings) {
    state.holdings = holdings;
  },
};

const actions = {
  async [actionTypes.GET_CURRENCIES_LIST]({ commit }) {
    try {
      const currencies = await CurrenciesRepository.getCurrenciesList();
      commit(mutationTypes.CURRENCIES_LIST, currencies);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_CURRENCIES_RATES]({
    // eslint-disable-next-line no-unused-vars
    commit, state, rootGetters,
  }, dates) {
    try {
      const currenciesRates = await CurrenciesRepository.getCurrenciesRates({
        selectedCurrencyId: state.selectedCurrency.id,
        dates,
      });
      const currenciesReportRates = await CurrenciesRepository.getCurrenciesRates({
        selectedCurrencyId: rootGetters['financeData/reportDataCompanyCurrency'],
        dates,
      });
      commit(mutationTypes.CURRENCIES_RATES, currenciesRates);
      commit(mutationTypes.CURRENCIES_REPORT_RATES, currenciesReportRates);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_CURRENCIES_DAILY_RATES]({ commit }) {
    try {
      const currenciesRates = await CurrenciesRepository.getCurrenciesDailyRates();
      commit(mutationTypes.CURRENCIES_DAILY_RATES, currenciesRates);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.SET_MANUAL_DAILY_RATES]({ commit }, currenciesRates) {
    commit(mutationTypes.CURRENCIES_DAILY_RATES, currenciesRates);
  },

  async [actionTypes.GET_REPORT_TYPES]({ commit }) {
    try {
      const reportTypes = await ReportRepository.getReportTypes();
      commit(mutationTypes.REPORT_TYPES, reportTypes);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_REPORT_LIST]({ commit }, payloads) {
    try {
      let reportList = [];
      if (payloads.reportType && payloads.reportType === REPORT_GLOBAL_CASH) {
        reportList = await ReportRepository.getCashPositionReports(payloads);
      } else if (payloads.reportType && payloads.reportType === CEO_REPORT) {
        reportList = await ReportRepository.getCeoNotesReports(payloads);
      } else {
        reportList = await ReportRepository.getReports(payloads);
      }
      // const reportList = await ReportRepository.getReports(payloads);
      commit(mutationTypes.REPORT_LIST, reportList);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_REPORT_ITEM]({ commit }, pk) {
    try {
      const reportItem = await ReportRepository.getReport(pk);
      commit(mutationTypes.REPORT_ITEM, reportItem);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_CEO_REPORT_ITEM]({ commit }, pk) {
    try {
      const reportItem = await ReportRepository.getCeoReport(pk);
      commit(mutationTypes.REPORT_ITEM, reportItem);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },
  async [actionTypes.UPDATE_CEO_REPORT_ITEM]({ commit }, payloads) {
    try {
      await ReportRepository.updateCeoReport(payloads.pk, payloads.text);
      return true;
    } catch (e) {
      console.log('e', e);
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_REPORT_CHANGE_LOGS]({ commit }, payloads) {
    const { pk, reportType } = payloads;
    try {
      const reportLogs = await ReportRepository.getReportChangeLogs(pk, { reportType });
      commit(mutationTypes.REPORT_CHANGE_LOGS, reportLogs);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_REPORT_CASH_ITEM]({ commit }, pk) {
    try {
      const reportItem = await ReportRepository.getCashReport(pk);
      commit(mutationTypes.REPORT_CASH_ITEM, reportItem);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.UPDATE_REPORT_ITEM]({ commit }, payloads) {
    try {
      await ReportRepository.updateReport(payloads);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.UPDATE_CASH_REPORT]({ commit }, payloads) {
    try {
      await ReportRepository.updateCashReport(payloads);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_COMPANIES_SETTINGS]({ commit }) {
    try {
      const companiesSettings = await ReportRepository.getCompaniesSettings();
      commit(mutationTypes.COMPANIES_SETTINGS, companiesSettings);
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_COMPANY_SETTING]({ commit }, payloads) {
    try {
      const companySetting = await ReportRepository.getCompanySetting(payloads.pk);
      commit(mutationTypes.COMPANY_SETTING, companySetting);
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.UPDATE_COMPANY_SETTING]({ commit }, payloads) {
    try {
      await ReportRepository.updateCompanySetting(payloads.pk, payloads.settingData);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.CREATE_COMPANY]({ commit }, payloads) {
    try {
      await ReportRepository.createCompany(payloads.settingData);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_COUNTRY_LIST]({ commit }) {
    try {
      const countries = await ReportRepository.getCountries();
      commit(mutationTypes.COUNTRY_LIST, countries);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_HOLDING_LIST]({ commit }) {
    try {
      const holdings = await ReportRepository.getHoldings();
      commit(mutationTypes.HOLDING_LIST, holdings);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_INSTITUTION_LIST]({ commit }) {
    try {
      const institutions = await ReportRepository.getInstitutions();
      commit(mutationTypes.INSTITUTION_LIST, institutions);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.REPORT_ERRORS, e.message);
      }
      return false;
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
