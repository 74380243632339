<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import ability, { defineAbilityFor } from './casl';
export default {
  name: 'app',
};
</script>

<style lang="scss">
  $base_bg_color: #f2f6fc;
  $base_text_color: #2c3e50;
  $bg_whithe: #ffffff;
  /* Start standart style */
  html, body, button {
    font-family: Roboto, "PingFang SC",
      "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "Arial", sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $base_text_color;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    background-color: transparent;
  }

</style>
