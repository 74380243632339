// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api';
import BaseRepositoryError from '@/repositories/base.errors';
import { camelToSnake } from '@/utils/string';

const NewsLetterRepository = {
  buildFormData: (payloads) => {
    const data = new FormData();
    Object.keys(payloads).forEach((key) => {
      if (payloads[key] === 'boolean') {
        data.append(camelToSnake(key), payloads[key]);
      } else if (payloads[key]) {
        data.append(camelToSnake(key), payloads[key]);
      }
    });
    return data;
  },

  async createNews(payloads) {
    /*
     * createNewsLetter
     * @returns newsletter object
     * @throws BaseRepositoryError
    */
    const data = this.buildFormData(payloads);
    const requestData = {
      method: 'post',
      url: '/newsletters/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async deleteNews(pk) {
    /*
     * deleteNews
     * @returns newsletter object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'delete',
      url: `/newsletters/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getNewsList: async (payloads) => {
    /*
     * getNewsList
     * @returns newsletters list object
     * @throws BaseRepositoryError
    */

    const requestData = {
      method: 'get',
      url: '/newsletters/',
      params: {
        page: payloads.page,
        page_size: payloads.page_size ? payloads.page_size : 10,
        is_published: payloads.isPublished ? payloads.isPublished : 'True',
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getNewsItem: async (pk) => {
    /*
     * getNewsItem
     * @returns newsletters list object
     * @throws BaseRepositoryError
    */

    const requestData = {
      method: 'get',
      url: `/newsletters/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  updateNewsItem: async (pk, data) => {
    /*
     * publishedNewsItem
     * @returns newsletters object
     * @throws BaseRepositoryError
    */

    const requestData = {
      method: 'put',
      url: `/newsletters/${pk}/`,
      data,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async updateNewsImageItem(pk, payloads) {
    /*
     * publishedNewsItem
     * @returns newsletters object
     * @throws BaseRepositoryError
    */

    const data = this.buildFormData(payloads);
    const requestData = {
      method: 'put',
      url: `/newsletters/${pk}/`,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async uploadRelatedImage(payloads) {
    /*
     * uploadRelatedImage
     * @returns newsletters object
     * @throws BaseRepositoryError
    */

    const data = this.buildFormData(payloads);
    const requestData = {
      method: 'post',
      url: '/newsletters-images/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default NewsLetterRepository;

export { NewsLetterRepository, BaseRepositoryError };
