// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api';
import BaseRepositoryError from '@/repositories/base.errors';
import { CASH_POSITION_REPORT } from '../configs';

const ReportRepository = {

  async getReportTypes() {
    /*
     * getTypesReport
     * @returns newsletter object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/reports/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getReports(payloads) {
    /*
     * getReports
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/company-reports/',
      params: {
        ...'isUpdated' in payloads && { is_submitted: payloads.isUpdated },
        ...'reportId' in payloads && { report: payloads.reportId },
        ...'company' in payloads && { company: payloads.company },
        page: payloads.page,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getCashPositionReports(payloads) {
    /*
     * getCashPositionReports
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/company-cash-position-reports/',
      params: {
        is_annual: false,
        ...'isUpdated' in payloads && { is_submitted: payloads.isUpdated },
        ...'reportId' in payloads && { report: payloads.reportId },
        ...'company' in payloads && { company: payloads.company },
        page: payloads.page,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getCeoNotesReports(payloads) {
    /*
     * getCeoNotesReports
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/company-ceo-notes-reports/',
      params: {
        ...'isUpdated' in payloads && { is_submitted: payloads.isUpdated },
        ...'reportId' in payloads && { report: payloads.reportId },
        ...'company' in payloads && { company: payloads.company },
        page: payloads.page,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getReport(pk) {
    /*
     * getReports
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: `/company-reports/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getCeoReport(pk) {
    /*
     * getReports
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: `/company-ceo-notes-reports/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async updateCeoReport(pk, text) {
    /*
     * updateCeoReport
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'put',
      url: `/company-ceo-notes-reports/${pk}/`,
      data: {
        ...text && { text },
        is_submitted: true,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getReportChangeLogs(pk, payloads) {
    /*
     * getReportChangeLogs
     * @params
     * payloads: object {
     *  reportType
     * }
     * @returns object
     * @throws BaseRepositoryError
    */
    let url = `/company-reports/${pk}/report_change_logs/`;
    if (payloads.reportType === CASH_POSITION_REPORT) {
      url = `/company-cash-position-reports/${pk}/report_change_logs/`;
    }
    const requestData = {
      method: 'get',
      url,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getCashReport(pk) {
    /*
     * getReports
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: `/company-cash-position-reports/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async updateReport(payloads) {
    /*
     * getReports
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'post',
      url: `/company-reports/${payloads.pk}/update_data/`,
      data: { ...payloads.indicatorsData },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async updateCashReport(payloads) {
    /*
     * updateCashReport
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'post',
      url: `/company-cash-position-reports/${payloads.pk}/update_indicators/`,
      data: { ...payloads.indicatorsData },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getCompaniesSettings() {
    /*
     * getCompaniesSettings
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/companies-settings/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getCompanySetting(pk) {
    /*
     * getCompaniesSettings
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: `/companies-settings/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async updateCompanySetting(pk, payloads) {
    /*
     * getCompaniesSettings
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'put',
      url: `/companies-settings/${pk}/`,
      data: {
        ...'ownership' in payloads && { ownership: payloads.ownership },
        ...'loaned' in payloads && { loaned: payloads.loaned },
        ...'name' in payloads && { name: payloads.name },
        ...'currency' in payloads && { currency: payloads.currency },
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async createCompany(payloads) {
    /*
     * createCompany
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'post',
      url: '/companies-settings/',
      data: {
        ...'ownership' in payloads && { ownership: payloads.ownership },
        ...'loaned' in payloads && { loaned: payloads.loaned },
        ...'name' in payloads && { name: payloads.name },
        ...'currency' in payloads && { currency: payloads.currency },
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getCountries() {
    /*
     * getCountries
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/countries/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getInstitutions() {
    /*
     * getInstitutions
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/institutions/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getHoldings() {
    /*
     * getHoldings
     * @returns object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/holdings/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

};

export default ReportRepository;
export { ReportRepository, BaseRepositoryError };
