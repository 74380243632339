import { actionTypes, mutationTypes } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import { NewsLetterRepository, BaseRepositoryError } from '@/repositories/newsletter';

const defaultState = () => ({
  news: {
    title: null,
    text: null,
    imageUrl: null,
  },
  newsletters: [],
  newsletterError: null,
  newsPagePublished: {
    page: 1,
    count: 1,
    page_size: 10,
  },
  newsPageUnPublished: {
    page: 1,
    count: 1,
    page_size: 10,
  },
});

const state = defaultState();

const getters = {
  newsletterError: (state) => state.newsletterError,
  newsletters: (state) => state.newsletters,
  newsPagePublished: (state) => state.newsPagePublished,
  newsPageUnPublished: (state) => state.newsPageUnPublished,
  newsItem: (state) => state.news,
};

const mutations = {
  [mutationTypes.NEWS_LIST](state, results) {
    state.newsletters = results;
  },
  [mutationTypes.CLEAR_NEWS_LIST](state) {
    state.newsletters = [];
  },
  [mutationTypes.NEWS_PAGE_PUBLISHED](state, payloads) {
    state.newsPagePublished.count = payloads.count;
    state.newsPagePublished.page = payloads.page;
  },
  [mutationTypes.NEWS_PAGE_UNPUBLISHED](state, payloads) {
    state.newsPageUnPublished.count = payloads.count;
    state.newsPageUnPublished.page = payloads.page;
  },
  [mutationTypes.NEWS_PAGE_NBR_PUBLISHED](state, page) {
    state.newsPagePublished.page = page;
  },
  [mutationTypes.NEWS_PAGE_NBR_UNPUBLISHED](state, page) {
    state.newsPageUnPublished.page = page;
  },
  [mutationTypes.NEWS_PAGE_SIZE](state, pageSize) {
    state.newsPagePublished.page_size = pageSize;
    state.newsPageUnPublished.page_size = pageSize;
  },
  [mutationTypes.NEWSLETTER_ERROR](state, errorMsg) {
    state.newsletterError = errorMsg;
  },
  [mutationTypes.SET_NEWSLETTER_PAGE](state, page) {
    state.newsPage.page = page;
  },
  [mutationTypes.NEWS_ITEM](state, newsItem) {
    state.news = {
      title: newsItem.title,
      text: newsItem.text,
      imageUrl: newsItem.image,
    };
  },
  [mutationTypes.CLEAR_NEWS_ITEM](state) {
    state.news = {
      title: null,
      text: null,
      imageUrl: null,
    };
  },
};

const actions = {
  async [actionTypes.GET_NEWS_LIST]({ commit }, payloads) {
    try {
      const newsList = await NewsLetterRepository.getNewsList(payloads);
      commit(mutationTypes.NEWS_LIST, newsList);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.GET_NEWS_LIST]({ commit, state }, payloads) {
    let data;
    if (payloads.isPublished === 'True') {
      // if newsletters == 1 we should reduce page,
      const { page } = state.newsPagePublished;
      if (state.newsletters.length === 1 && page - 1 > 0) {
        commit(mutationTypes.NEWS_PAGE_NBR_PUBLISHED, page - 1);
      }
      data = { ...payloads, ...state.newsPagePublished };
    } else {
      // if newsletters == 1 we should reduce page,
      const { page } = state.newsPagePublished;
      if (state.newsletters.length === 1 && page - 1 > 0) {
        commit(mutationTypes.NEWS_PAGE_NBR_UNPUBLISHED, page - 1);
      }
      data = { ...payloads, ...state.newsPageUnPublished };
    }
    try {
      const newsList = await NewsLetterRepository.getNewsList(data);
      commit(mutationTypes.NEWS_LIST, newsList.results);
      if (payloads.isPublished === 'True') {
        commit(mutationTypes.NEWS_PAGE_PUBLISHED, newsList);
      } else {
        commit(mutationTypes.NEWS_PAGE_UNPUBLISHED, newsList);
      }
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.CREATE_NEWS]({ commit }, payloads) {
    try {
      await NewsLetterRepository.createNews(payloads);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.DELETE_NEWS]({ commit }, pk) {
    try {
      await NewsLetterRepository.deleteNews(pk);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.UPDATE_NEWS]({ commit }, payloads) {
    try {
      await NewsLetterRepository.updateNewsItem(payloads.pk, payloads.data);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.UPDATE_IMG_NEWS]({ commit }, payloads) {
    try {
      await NewsLetterRepository.updateNewsImageItem(payloads.pk, payloads.data);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.GET_NEWS]({ commit }, pk) {
    try {
      const newsItem = await NewsLetterRepository.getNewsItem(pk);
      commit(mutationTypes.NEWS_ITEM, newsItem);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.UPLOAD_NEWS_IMAGE]({ commit }, payloads) {
    try {
      const newsItem = await NewsLetterRepository.updateNewsImageItem(payloads);
      return newsItem;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.NEWSLETTER_ERROR, e.message);
      }
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
