// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api';
import BaseRepositoryError from '@/repositories/base.errors';
import { camelToSnake } from '@/utils/string';

const EmployeeRepository = {
  getEmployeeItem: async (pk) => {
    /*
     * getEmployeeItem
     * @returns employee object
     * @throws BaseRepositoryError
     */
    const requestData = {
      method: 'get',
      url: `/employees/${pk}/`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getEmployeeList: async (payloads) => {
    /*
     * getEmployeeList
     * @returns employee array
     * @throws BaseRepositoryError
     */
    const requestData = {
      method: 'get',
      url: '/employees/',
      params: {
        page: payloads.paginationPage,
      },
    };
    if (payloads.search) {
      requestData.params.search = payloads.search;
    }
    if (payloads.page) {
      requestData.params.page = payloads.page;
    }
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  createEmployee: async (payloads) => {
    /*
     * createEmployee
     * @returns employee object
     * @throws BaseRepositoryError
   */
    const data = new FormData();
    Object.keys(payloads).forEach((key) => {
      if (['companies', 'title_obj'].includes(key)) {
        //
      } else if (payloads[key] === 'boolean') {
        data.append(camelToSnake(key), payloads[key]);
      } else if (payloads[key]) {
        if (key === 'companyIds') {
          payloads[key].forEach((el) => {
            data.append(camelToSnake(key), el);
          });
        } else {
          data.append(camelToSnake(key), payloads[key]);
        }
      }
    });
    data.append('is_active', true);
    const requestData = {
      method: 'post',
      url: '/employees/',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  editEmployee: async (pk, payloads) => {
    /*
     * editJsonEmployee - update data of the employee
     * @returns employee object
     * @throws BaseRepositoryError
    */
    const data = new FormData();
    Object.keys(payloads).forEach((key) => {
      if (['companies', 'title_obj', 'title_obj'].includes(key)) {
        //
      } else if (payloads[key] === 'boolean') {
        data.append(camelToSnake(key), payloads[key]);
      } else if (key === 'companyIds') {
        payloads[key].forEach((el) => {
          data.append(camelToSnake(key), el);
        });
      } else {
        data.append(camelToSnake(key), payloads[key]);
      }
    });
    const requestData = {
      method: 'put',
      url: `/employees/${pk}/`,
      data,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getTitlesList: async () => {
    /*
     * getTitles - get employee titles
     * @returns employee list object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'get',
      url: '/employees-setting/titles/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  createEmployeeTitle: async (payloads) => {
    /*
     * getTitles - get employee titles
     * @returns employee list object
     * @throws BaseRepositoryError
    */
    const requestData = {
      method: 'post',
      url: '/employees-setting/titles/',
      data: {
        title: payloads.title,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  setPassword: async (pk, payloads) => {
    /*
     * setPassword
     * @returns result
     * @throws BaseRepositoryError
   */

    const requestData = {
      method: 'post',
      // url: '/rest-auth/password/change/',
      url: `/employees/${pk}/set_password/`,
      data: {
        password: payloads.password1,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getCompanyList: async () => {
    /*
     * getCompanyList
     * @returns company list
     * @throws BaseRepositoryError
   */
    const requestData = {
      method: 'get',
      url: '/companies/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
  getSkillList: async () => {
    /*
     * getSkillList
     * @returns skill list
     * @throws BaseRepositoryError
   */
    const requestData = {
      method: 'get',
      url: '/skills/',
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default EmployeeRepository;

export { EmployeeRepository, BaseRepositoryError };
