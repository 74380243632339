// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api';
import TokenService from '@/services/storage/token';
import BaseRepositoryError from '@/repositories/base.errors';

const UserRepository = {
  /*
   * Login the user and store the access token to TokenService.
   * @returns access_token
   * @throws BaseRepositoryError
  */
  login: async (email, password) => {
    const requestData = {
      method: 'post',
      url: '/api-token-auth/',
      data: {
        email,
        password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.token);
      TokenService.saveRefreshToken(response.data.token);
      ApiService.setAuthHeader();
      // NOTE: We haven't covered this yet in our ApiService
      //       but don't worry about this just yet - I'll come back to it later
      ApiService.mount401Interceptor();
      return response.data.token;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  /**
   * Refresh the access token.
  * */
  refreshToken: async () => {
    const refreshToken = TokenService.getRefreshToken();
    const requestData = {
      method: 'post',
      url: '/api-token-refresh/',
      data: {
        refreshToken,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      // Update the header in ApiService
      ApiService.setAuthHeader();
      ApiService.mount401Interceptor();

      return response.data.token;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
  * */
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();
    // NOTE: Again, we'll cover the 401 Interceptor a bit later.
    ApiService.unmount401Interceptor();
  },

  resetPasswordSend: async (email) => {
    const requestData = {
      method: 'post',
      url: '/rest-auth/password/reset/',
      data: {
        email,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data.detail;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  resetPasswordChange: async (uid, token, password1, password2) => {
    const requestData = {
      method: 'post',
      url: '/password-reset/change/',
      data: {
        uid,
        token,
        new_password1: password1,
        new_password2: password2,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data.detail;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default UserRepository;

export { UserRepository, BaseRepositoryError };
