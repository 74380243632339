const NON_FIELD_ERROR = 'non_field_errors';

class BaseRepositoryError extends Error {
  constructor(errorCode, response) {
    super();
    if (errorCode !== 500) {
      this.message = this.parseErrorData(response.data);
    } else {
      this.message = 'Server Error. Please, contact support.';
    }
    this.name = this.constructor.name;
    this.errorCode = errorCode;
  }

  parseErrorData = (data) => {
    let message = '';
    Object.keys(data).forEach((key, index) => {
      if (data[key] instanceof Array) {
        if (key !== NON_FIELD_ERROR) {
          message = index !== 0 ? `${message}, ${key}` : `${key}`;
        } else {
          message = '';
        }
        data[key].forEach((value) => {
          message = `${message} ${value}`;
          console.log('key', value);
        });
      } else if (key !== NON_FIELD_ERROR) {
        message = `${key} ${data[key]}`;
      } else {
        message = data[key];
      }
    });
    return message;
  };
}

export default BaseRepositoryError;
