import Vue from 'vue';
import moment from 'moment';

import { Decimal } from 'decimal.js';
import { CURRENCY_ICONS } from './configs';

const fillNull = (value) => value || '---';

const truncate = (value, count) => {
  if (value && value.length > count) {
    return `${value.substring(0, count)} ...`;
  }
  return value || '---';
};

const dasharrayPercent = (value, total) => {
  const percent = (value / total) * 100;
  return `${percent} ${100 - percent}`;
};

const strippedContent = (value) => value.replace(/<\/?[^>]+>/ig, ' ') || ' ';

// eslint-disable-next-line no-unused-vars
const currencyRate = (value, rate) => {
  if (typeof value !== 'number') {
    return value;
  }
  if (rate) {
    return new Decimal(value).mul(rate).toFixed(2);
  }
  return new Decimal(value).toFixed(2);
};

const financeDigit = (value) => {
  if (value < 0) {
    return `(${parseFloat(value * -1).toLocaleString('us',
      { minimumFractionDigits: 2 })})`;
  }
  return `${parseFloat(value).toLocaleString('us',
    { minimumFractionDigits: 2 })}`;
};

const currencyLabel = (symbol) => (symbol in CURRENCY_ICONS ? `${CURRENCY_ICONS[symbol]} ${symbol}`
  : symbol);

const momentFormatDate = (date) => (moment(date).format('MM Do YYYY, h:mm:ss a'));

const formatDate = (date) => (moment(date).format('YYYY-MM-DD'));

const formatExchangeDailyRate = (value, isReversed) => {
  if (isReversed) {
    return new Decimal(100 / (100 * value)).toFixed(4);
  }
  return new Decimal(value).toFixed(4);
};

Vue.filter('fillNull', fillNull);
Vue.filter('truncate', truncate);
Vue.filter('strippedContent', strippedContent);
Vue.filter('dasharrayPercent', dasharrayPercent);
Vue.filter('currencyRate', currencyRate);
Vue.filter('financeDigit', financeDigit);
Vue.filter('currencyLabel', currencyLabel);
Vue.filter('momentFormatDate', momentFormatDate);
Vue.filter('formatDate', formatDate);
Vue.filter('formatExchangeDailyRate', formatExchangeDailyRate);
