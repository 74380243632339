// ability.js
import { AbilityBuilder, Ability } from '@casl/ability';
import {
  VISIBILITY_NO_ACCESS, VISIBILITY_ADMIN, VISIBILITY_COMPANY, NEWS_CREATOR, NEWS_PUBLISHER,
} from '@/configs';

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */

// const { rules, can, cannot } = AbilityBuilder.extract();

export const defaultAbility = new Ability();

export const defineAbilityFor = (user) => {
  const { rules, can, cannot } = AbilityBuilder.extract();
  if (user.employeeVisibility >= VISIBILITY_COMPANY) {
    can('read', 'employee');
  }
  if (user.employeeVisibility === VISIBILITY_ADMIN) {
    // can('edit', 'employee', ['avatar', 'dateOfBirth', 'about', 'phone']);
    can('manage', 'employee');
  }
  if (user.newslettersVisibility !== VISIBILITY_NO_ACCESS) {
    can('read', 'newsletters');
  }
  if (user.newslettersUserType === NEWS_CREATOR
      && user.newslettersVisibility !== VISIBILITY_NO_ACCESS) {
    can('create', 'newsletters');
    cannot('publish', 'newsletters');
  }
  if (user.newslettersUserType === NEWS_PUBLISHER
    && user.newslettersVisibility !== VISIBILITY_NO_ACCESS) {
    can(['manage', 'publish'], 'newsletters');
  }

  if (user.isCompany) {
    can('manage', 'finance');
  }

  if (user.isAdmin) {
    can('manage', 'finance');
    can('manage', 'employee', 'all');
    can(['manage', 'publish'], 'newsletters');
  }
  return defaultAbility.update(rules);
};

export default AbilityBuilder.define((can) => {
  can('manage', 'all');
});
