export const actionTypes = {
  IS_MOBILE: 'IS_MOBILE',
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  RESET_PASSWORD_SEND: 'RESET_PASSWORD_SEND',
  RESET_PASSWORD_CHANGE: 'RESET_PASSWORD_CHANGE',
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_CACHE_INFO: 'GET_USER_CACHE_INFO',
  SET_USER_CACHE_INFO: 'SET_USER_CACHE_INFO',
  // PROFILE
  EDIT_OWN_USER: 'EDIT_OWN_USER',
  SET_OWN_PASSWORD: 'SET_OWN_PASSWORD',
  // EMPLOYEE
  GET_EMPLOYEE_LIST: 'GET_EMPLOYEE_LIST',
  GET_EMPLOYEE_ITEM: 'GET_EMPLOYEE_ITEM',
  GET_EMPLOYEE_COMPANY_LIST: 'GET_EMPLOYEE_COMPANY_LIST',
  GET_EMPLOYEE_SKILL_LIST: 'GET_EMPLOYEE_SKILL_LIST',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  EDIT_EMPLOYEE: 'EDIT_EMPLOYEE',
  SET_PASSWORD: 'SET_PASSWORD',
  GET_EMPLOYEE_TITLES_LIST: 'GET_EMPLOYEE_TITLES_LIST',
  CREATE_EMPLOYEE_TITLE: 'CREATE_EMPLOYEE_TITLE',
  // NEWSLETTERS
  CREATE_NEWS: 'CREATE_NEWS',
  DELETE_NEWS: 'DELETE_NEWS',
  UPDATE_NEWS: 'UPDATE_NEWS',
  UPDATE_IMG_NEWS: 'UPDATE_IMG_NEWS',
  GET_NEWS: 'GET_NEWS',
  GET_NEWS_LIST: 'GET_NEWS_LIST',
  UPLOAD_NEWS_IMAGE: 'UPLOAD_NEWS_IMAGE',
  // FINANCE REPORTS
  GET_REPORT_TYPES: 'GET_REPORT_TYPES',
  GET_REPORT_LIST: 'GET_REPORT_LIST',
  GET_REPORT_ITEM: 'GET_REPORT_ITEM',
  GET_CEO_REPORT_ITEM: 'GET_CEO_REPORT_ITEM',
  UPDATE_CEO_REPORT_ITEM: 'UPDATE_CEO_REPORT_ITEM',
  GET_REPORT_CASH_ITEM: 'GET_REPORT_CASH_ITEM',
  GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
  GET_HOLDING_LIST: 'GET_HOLDING_LIST',
  GET_INSTITUTION_LIST: 'GET_INSTITUTION_LIST',
  UPDATE_REPORT_ITEM: 'UPDATE_REPORT_ITEM',
  UPDATE_CASH_REPORT: 'UPDATE_CASH_REPORT',
  GET_REPORT_CHANGE_LOGS: 'GET_REPORT_CHANGE_LOGS',
  // FINANCE DATA
  GET_REPORT_DATA: 'GET_REPORT_DATA',
  GET_REPORT_DATA_STATISTIC: 'GET_REPORT_DATA_STATISTIC',
  GET_REPORT_DATA_DATES: 'GET_REPORT_DATA_DATES',
  CREATE_REPORT: 'CREATE_REPORT',
  // FINANCE SETTINGS
  GET_COMPANIES_SETTINGS: 'GET_COMPANIES_SETTINGS',
  GET_COMPANY_SETTING: 'GET_COMPANY_SETTING',
  UPDATE_COMPANY_SETTING: 'UPDATE_COMPANY_SETTING',
  CREATE_COMPANY: 'CREATE_COMPANY',
  //  dddd
  GET_CURRENCIES_LIST: 'GET_CURRENCIES_LIST',
  GET_CURRENCIES_RATES: 'GET_CURRENCIES_RATES',
  GET_CURRENCIES_DAILY_RATES: 'GET_CURRENCIES_DAILY_RATES',
  SET_MANUAL_DAILY_RATES: 'SET_MANUAL_DAILY_RATES',
};

export const mutationTypes = {
  IS_MOBILE: 'IS_MOBILE',
  // GLOBAL HANDLER
  GLOBAL_LOADER_SWITCH: 'GLOBAL_LOADER_SWITCH',
  GLOBAL_LOADER_SET: 'GLOBAL_LOADER_SET',
  // AUTH
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REFRESH_TOKEN_PROMISE: 'REFRESH_TOKEN_PROMISE',
  LOGOUT_USER: 'LOGOUT_USER',
  AUTH_ERROR: 'AUTH_ERROR',
  // USER
  USER_INFO: 'USER_INFO',
  USER_ERROR: 'USER_ERROR',
  USER_CACHE_INFO: 'USER_CACHE_INFO',
  // EMPLOYEE
  EMPLOYEE_ERROR: 'EMPLOYEE_ERROR',
  EMPLOYEE_COMPANY_LIST: 'EMPLOYEE_COMPANY_LIST',
  EMPLOYEE_LIST: 'EMPLOYEE_LIST',
  EMPLOYEE_DESTROY_ITEM: 'EMPLOYEE_DESTROY_ITEM',
  EMPLOYEE_ITEM: 'EMPLOYEE_ITEM',
  EMPLOYEE_SKILL_LIST: 'EMPLOYEE_SKILL_LIST',
  EMPLOYEE_TITLES_LIST: 'EMPLOYEE_TITLES_LIST',
  // NEWSLETTERS
  NEWSLETTER_ERROR: 'NEWSLETTER_ERROR',
  SET_NEWSLETTER_PAGE: 'SET_NEWSLETTER_PAGE',
  NEWS_PAGE_PUBLISHED: 'GET_NEWS_LIST_PUBLISHED',
  NEWS_PAGE_UNPUBLISHED: 'GET_NEWS_LIST_UNPUBLISHED',
  NEWS_PAGE_NBR_UNPUBLISHED: 'NEWS_PAGE_NBR_UNPUBLISHED',
  NEWS_PAGE_NBR_PUBLISHED: 'NEWS_PAGE_NBR_PUBLISHED',
  NEWS_LIST: 'NEWS_LIST',
  CLEAR_NEWS_LIST: 'CLEAR_NEWS_LIST',
  NEWS_ITEM: 'NEWS_ITEM',
  CLEAR_NEWS_ITEM: 'CLEAR_NEWS_ITEM',
  NEWS_PAGE_SIZE: 'NEWS_PAGE_SIZE',
  UPLOAD_NEWS_IMAGE: 'UPLOAD_NEWS_IMAGE',
  // FINANCE REPORTS
  REPORT_ERRORS: 'REPORT_ERRORS',
  REPORT_TYPES: 'REPORT_TYPES',
  REPORT_LIST: 'REPORT_LIST',
  CLEAR_REPORT_LIST: 'CLEAR_REPORT_LIST',
  REPORT_ITEM: 'REPORT_ITEM',
  CLEAR_REPORT_ITEM: 'CLEAR_REPORT_ITEM',
  REPORT_CASH_ITEM: 'REPORT_CASH_ITEM',
  COUNTRY_LIST: 'COUNTRY_LIST',
  HOLDING_LIST: 'HOLDING_LIST',
  INSTITUTION_LIST: 'INSTITUTION_LIST',
  REPORT_CHANGE_LOGS: 'REPORT_CHANGE_LOGS',
  // FINANCE DATA
  CREATED_REPORTS: 'CREATED_REPORTS',
  REPORT_DATA_ERRORS: 'REPORT_DATA_ERRORS',
  REPORT_DATA: 'REPORT_DATA',
  CLEAR_REPORT_DATA: 'CLEAR_REPORT_DATA',
  REPORT_DATA_STATISTIC: 'REPORT_DATA_STATISTIC',
  REPORT_DATA_DATES: 'REPORT_DATA_DATES',
  // FINANCE SETTINGS
  COMPANIES_SETTINGS: 'COMPANIES_SETTINGS',
  COMPANY_SETTING: 'COMPANY_SETTING',
  //  dd
  CURRENCIES_LIST: 'CURRENCIES_LIST',
  SELECTED_CURRENCY: 'SELECTED_CURRENCY',
  CURRENCIES_RATES: 'CURRENCIES_RATES',
  CURRENCIES_DAILY_RATES: 'CURRENCIES_DAILY_RATES',
  CURRENCIES_REPORT_RATES: 'CURRENCIES_REPORT_RATES',
  CREATE_COMPANY: 'CREATE_COMPANY',
};
