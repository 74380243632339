import Vue from 'vue';
import VueRouter from 'vue-router';
import { USER_TYPES_URL_NAME } from '@/configs';
import VueRouterMiddleware, { middleware, createMiddleware } from 'vue-router-middleware';
import { mutationTypes, actionTypes } from '@/store/types';

// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import TokenService from '@/services/storage/token';
import { defineAbilityFor } from '../casl';

createMiddleware('private', async (args, to, from, next) => {
  // load user information
  const res = await store.dispatch(`user/${actionTypes.GET_USER_INFO}`);
  if (!res) {
    store.dispatch(`auth/${actionTypes.LOGOUT_USER}`);
  }
  if (!store.state.auth.isAuthenticated) {
    const accessToken = TokenService.getToken();
    if (accessToken) {
      store.commit(`auth/${mutationTypes.LOGIN_SUCCESS}`, accessToken);
    }
  }
  if (store.state.auth.isAuthenticated && to.matched[0].regex.test('/auth')) {
    next({ name: 'admin-dashboard' });
  } else if (!store.state.auth.isAuthenticated && !to.matched[0].regex.test('/auth')) {
    next({ name: 'login' });
  } else {
    next();
  }
});

createMiddleware('protect_cabinet', async (args, to, from, next) => {
  const ability = defineAbilityFor(store.state.user.userInfo);
  const { userType } = store.state.user.userInfo;
  if (!userType) {
    next({ name: 'login' });
  }
  if (!(to.matched[0].name === USER_TYPES_URL_NAME[userType])) {
    next({ name: USER_TYPES_URL_NAME[userType] });
  } else {
    // check permission for employees-edit
    if (to.name.includes('employees-list')) {
      if (!ability.can('read', 'employee')) {
        next({ name: USER_TYPES_URL_NAME[userType] });
      }
    }
    // check permission for employees-edit
    if (to.name.includes('employees-edit')) {
      if (!ability.can('edit', 'employee')) {
        next({ name: USER_TYPES_URL_NAME[userType] });
      }
    }
    if (to.name.includes('employees-create')) {
      if (!ability.can('create', 'employee')) {
        next({ name: USER_TYPES_URL_NAME[userType] });
      }
    }
    /*
      newsletters
    */
    // check permission for employees-edit
    if (to.name.includes('newsletters-list')) {
      if (!ability.can('read', 'newsletters')) {
        next({ name: USER_TYPES_URL_NAME[userType] });
      }
    }
    // check permission for employees-create
    if (to.name.includes('newsletters-view')) {
      if (!ability.can('read', 'newsletters')) {
        next({ name: USER_TYPES_URL_NAME[userType] });
      }
    }
    if (to.name.includes('newsletters-create')) {
      if (!ability.can('create', 'newsletters')) {
        next({ name: USER_TYPES_URL_NAME[userType] });
      }
    }
    if (to.name.includes('newsletters-edit')) {
      if (!ability.can('edit', 'newsletters')) {
        next({ name: USER_TYPES_URL_NAME[userType] });
      }
    }
    // check permission for finance
    // if (to.name.includes('finance')) {
    //   if (!ability.can('read', 'finance')) {
    //     next({ name: USER_TYPES_URL_NAME[userType] });
    //   }
    // }
    next();
  }
});

const routes = [
  ...middleware('private', [
    ...middleware('protect_cabinet', [
      {
        path: '/',
        name: 'employee-cabinet',
        component: () => import(/* webpackChunkName: "auth" */'@base/views/CabinetEmployee'),
        redirect: { name: 'employee-dashboard' },
        children: [
          {
            path: 'profile/edit',
            name: 'employee-own-edit-profile',
            component: () => import('@base/views/OwnProfile'),
          },
          {
            path: 'profile/view',
            name: 'employee-own-view-profile',
            component: () => import('@base/views/OwnProfileView'),
          },
          {
            path: 'dashboard',
            name: 'employee-dashboard',
            component: () => import('@base/views/CabinetEmployee/Dashboard'),
          },
          {
            path: 'employees',
            name: 'employee-employees',
            component: () => import('@base/views/EmployeeDirectory'),
            redirect: { name: 'employee-employees-list' },
            children: [
              {
                path: 'list/:search?',
                name: 'employee-employees-list',
                component: () => import('@/views/EmployeeDirectory/EmployeeList'),
              },
              {
                path: 'create',
                name: 'employee-employees-create',
                component: () => import('@/views/EmployeeDirectory/EmployeeCreate'),
              },
              {
                path: 'view/:pk/',
                name: 'employee-employees-view',
                component: () => import('@/views/EmployeeDirectory/EmployeeView'),
              },
              {
                path: 'edit/:pk/',
                name: 'employee-employees-edit',
                component: () => import('@/views/EmployeeDirectory/EmployeeEdit'),
              },
            ],
          },
          {
            path: 'newsletters',
            name: 'employee-newsletters',
            component: () => import('@base/views/Newsletters'),
            redirect: { name: 'employee-newsletters-list' },
            children: [
              {
                path: 'list',
                name: 'employee-newsletters-list',
                component: () => import('@/views/Newsletters/NewslettersList'),
              },
              {
                path: 'create',
                name: 'employee-newsletters-create',
                component: () => import('@/views/Newsletters/NewslettersCreate'),
              },
              {
                path: 'edit/:pk/',
                name: 'employee-newsletters-edit',
                component: () => import('@/views/Newsletters/NewslettersEdit'),
              },
              {
                path: 'view/:pk/',
                name: 'employee-newsletters-view',
                component: () => import('@/views/Newsletters/NewslettersView'),
              },
            ],
          },
        ],
      },
    ]),
    ...middleware('protect_cabinet', [
      {
        path: '/admin',
        name: 'admin-cabinet',
        component: () => import('@base/views/CabinetAdmin'),
        redirect: { name: 'admin-dashboard' },
        children: [
          {
            path: 'profile/edit',
            name: 'admin-own-edit-profile',
            component: () => import('@base/views/OwnProfile'),
          },
          {
            path: 'dashboard',
            name: 'admin-dashboard',
            component: () => import('@base/views/CabinetAdmin/Dashboard'),
          },
          {
            path: 'employees',
            name: 'admin-employees',
            component: () => import('@/views/EmployeeDirectory'),
            redirect: { name: 'admin-employees-list' },
            children: [
              {
                path: 'list/:search?',
                name: 'admin-employees-list',
                component: () => import('@/views/EmployeeDirectory/EmployeeList'),
              },
              {
                path: 'create',
                name: 'admin-employees-create',
                component: () => import('@/views/EmployeeDirectory/EmployeeCreate'),
              },
              {
                path: 'view/:pk/',
                name: 'admin-employees-view',
                component: () => import('@/views/EmployeeDirectory/EmployeeView'),
              },
              {
                path: 'edit/:pk/',
                name: 'admin-employees-edit',
                component: () => import('@/views/EmployeeDirectory/EmployeeEdit'),
              },
            ],
          },
          {
            path: 'newsletters',
            name: 'admin-newsletters',
            component: () => import('@base/views/Newsletters'),
            redirect: { name: 'admin-newsletters-list' },
            children: [
              {
                path: 'list',
                name: 'admin-newsletters-list',
                component: () => import('@/views/Newsletters/NewslettersList'),
              },
              {
                path: 'create',
                name: 'admin-newsletters-create',
                component: () => import('@/views/Newsletters/NewslettersCreate'),
              },
              {
                path: 'edit/:pk/',
                name: 'admin-newsletters-edit',
                component: () => import('@/views/Newsletters/NewslettersEdit'),
              },
              {
                path: 'view/:pk/',
                name: 'admin-newsletters-view',
                component: () => import('@/views/Newsletters/NewslettersView'),
              },
            ],
          },
          {
            path: 'report',
            name: 'admin-report',
            component: () => import('@base/views/Finance'),
            redirect: { name: 'admin-finance-setting-view' },
            children: [
              {
                path: 'reports/:isUpdated/:reportId?/:reportType?',
                name: 'admin-report-list',
                component: () => import('@base/views/Finance/FinanceReportList'),
              },
              {
                path: 'report/edit/:reportTypeId/:reportPk/',
                name: 'admin-report-edit',
                component: () => import('@base/views/Finance/FinanceReportEdit'),
              },
              {
                path: 'ceo-report/edit/:reportTypeId/:reportPk/',
                name: 'admin-ceo-report-edit',
                component: () => import('@base/views/Finance/FinanceReportCEOEdit'),
              },
              {
                path: 'cash-report/edit/:reportTypeId/:reportPk/',
                name: 'admin-report-cash-edit',
                component: () => import('@base/views/Finance/FinanceReportCashEdit'),
              },
              {
                path: 'report-view/:reportTypeId/:dateFrom/:dateTo/:selectedCompany',
                name: 'admin-report-view',
                component: () => import('@base/views/Finance/FinanceReportView'),
              },
            ],
          },
          {
            path: 'finance',
            name: 'admin-finance-setting',
            component: () => import('@base/views/FinanceSettings'),
            redirect: { name: 'admin-finance-setting-companies' },
            children: [
              {
                path: 'companies',
                name: 'admin-finance-setting-companies',
                component: () => import('@base/views/FinanceSettings/Companies'),
              },
              {
                path: 'company/create',
                name: 'admin-finance-setting-company-create',
                component: () => import('@base/views/FinanceSettings/CompanyCreate'),
              },
              {
                path: 'company/:companyPk',
                name: 'admin-finance-setting-company',
                component: () => import('@base/views/FinanceSettings/Company'),
              },
              {
                path: 'reports',
                name: 'admin-finance-setting-reports',
                component: () => import('../views/FinanceSettings/Reports.vue'),
              },
            ],
          },
        ],
      },
    ]),
    ...middleware('protect_cabinet', [
      {
        path: '/company',
        name: 'company-cabinet',
        component: () => import(/* webpackChunkName: "auth" */'@base/views/CabinetCompany'),
        redirect: { name: 'company-dashboard' },
        children: [
          {
            path: 'profile/edit',
            name: 'company-own-edit-profile',
            component: () => import('@base/views/OwnProfile'),
          },
          {
            path: 'profile/view',
            name: 'company-own-view-profile',
            component: () => import('@base/views/OwnProfileView'),
          },
          {
            path: 'dashboard',
            name: 'company-dashboard',
            component: () => import('@base/views/CabinetCompany/Dashboard'),
          },
          {
            path: 'newsletters',
            name: 'company-newsletters',
            component: () => import('@base/views/Newsletters'),
            redirect: { name: 'company-newsletters-list' },
            children: [
              {
                path: 'list',
                name: 'company-newsletters-list',
                component: () => import('@/views/Newsletters/NewslettersList'),
              },
              {
                path: 'create',
                name: 'company-newsletters-create',
                component: () => import('@/views/Newsletters/NewslettersCreate'),
              },
              {
                path: 'edit/:pk/',
                name: 'company-newsletters-edit',
                component: () => import('@/views/Newsletters/NewslettersEdit'),
              },
              {
                path: 'view/:pk/',
                name: 'company-newsletters-view',
                component: () => import('@/views/Newsletters/NewslettersView'),
              },
            ],
          },
          {
            path: 'employees',
            name: 'company-employees',
            component: () => import('@base/views/EmployeeDirectory'),
            redirect: { name: 'company-employees-list' },
            children: [
              {
                path: 'list/:search?',
                name: 'company-employees-list',
                component: () => import('@/views/EmployeeDirectory/EmployeeList'),
              },
              {
                path: 'create',
                name: 'company-employees-create',
                component: () => import('@/views/EmployeeDirectory/EmployeeCreate'),
              },
              {
                path: 'view/:pk/',
                name: 'company-employees-view',
                component: () => import('@/views/EmployeeDirectory/EmployeeView'),
              },
              {
                path: 'edit/:pk/',
                name: 'company-employees-edit',
                component: () => import('@/views/EmployeeDirectory/EmployeeEdit'),
              },
            ],
          },
          {
            path: 'finance',
            name: 'company-finance',
            component: () => import('@base/views/Finance'),
            redirect: { name: 'company-finance-dashboard' },
            children: [
              {
                path: '',
                name: 'company-finance-dashboard',
                component: () => import('@base/views/Finance/FinanceDashboard'),
              },
              {
                path: 'reports/:isUpdated/:reportId?/:reportType?',
                name: 'company-report-list',
                component: () => import('@base/views/Finance/FinanceReportList'),
              },
              {
                path: 'reports/edit/:reportTypeId/:reportPk/',
                name: 'company-report-edit',
                component: () => import('@base/views/Finance/FinanceReportEdit'),
              },
              {
                path: 'ceo-report/edit/:reportTypeId/:reportPk/',
                name: 'company-ceo-report-edit',
                component: () => import('@base/views/Finance/FinanceReportCEOEdit'),
              },
              {
                path: 'report-view/:reportTypeId/',
                name: 'company-report-view',
                component: () => import('@base/views/Finance/FinanceReportView'),
              },
            ],
          },
        ],
      },
    ]),
  ]),
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */'@base/views/AuthView'),
    redirect: { name: 'login' },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */'@base/views/AuthView/LoginBase'),
      },
      {
        path: 'forgot-password-send',
        name: 'forgot-password-send',
        component: () => import(/* webpackChunkName: "auth" */'@base/views/AuthView/ForgotPasswordSend'),
      },
      {
        path: 'forgot-password-sent',
        name: 'forgot-password-sent',
        component: () => import(/* webpackChunkName: "auth" */'@base/views/AuthView/ForgotPasswordSent'),
      },
      {
        path: 'password-reset/:uid?/:token?/',
        name: 'forgot-password-change',
        component: () => import(/* webpackChunkName: "auth" */'@base/views/AuthView/ForgotPasswordConfirm'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

Vue.use(VueRouterMiddleware, { router });
Vue.use(VueRouter);

export default router;
