import Vue from 'vue';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VueTelInput from 'vue-tel-input';

import { abilitiesPlugin, Can } from '@casl/vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import ApiService from './services/api';

import TokenService from './services/storage/token';
import { actionTypes, mutationTypes } from './store/types';

import { apiUrl } from './configs';
import Filters from './filters';

import './styles/index.scss';

import { defaultAbility } from './casl';

ApiService.init(apiUrl.baseURL);
// If token exists set header
if (TokenService.getToken()) {
  ApiService.setAuthHeader();
}

Vue.use(abilitiesPlugin, defaultAbility);
Vue.component('can', Can);

Vue.config.productionTip = false;

Vue.use(Element, { locale });
Vue.use(VueTelInput);

Vue.prototype.$_actionTypes = actionTypes;
Vue.prototype.$_mutationTypes = mutationTypes;
Vue.prototype.$_globalEvent = new Vue(); // Global event bus;

Vue.component('font-awesome-icon', FontAwesomeIcon);

// TODO: the next line is added for debugging purposes and should be removed for production build
// window.ability = ability;

new Vue({
  router,
  store,
  i18n,
  components: { Filters },
  render: (h) => h(App),
}).$mount('#app');
