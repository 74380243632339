/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import user from '@/store/modules/user';
import employee from '@/store/modules/employee';
import newsletter from '@/store/modules/newsletter';
import financeReports from '@/store/modules/finance.reports';
import financeData from '@/store/modules/finance.data';

import { mutationTypes } from '@/store/types';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    employee,
    newsletter,
    financeReports,
    financeData,
  },

  state: {
    globalLoader: false,
    isMobile: false,
  },

  getters: {
    globalLoader: (state) => state.globalLoader,
    isMobile: (state) => state.isMobile,
  },

  mutations: {
    [mutationTypes.GLOBAL_LOADER_SET](state, isShow) {
      state.globalLoader = isShow;
    },
    [mutationTypes.GLOBAL_LOADER_SWITCH](state) {
      state.globalLoader = !state.globalLoader;
    },
    [mutationTypes.IS_MOBILE](state, isMobile) {
      state.isMobile = isMobile;
    },
  },
});
