import { actionTypes, mutationTypes } from '@/store/types';
// eslint-disable-next-line import/no-cycle
import { EmployeeRepository, BaseRepositoryError } from '@/repositories/employee';
import { COMPANY_USER } from '@/configs';
import { formatProfile } from '../formatters';

const DEFAULT_EMPLOYEE = {
  pk: null,
  fullName: null,
  firstName: null,
  lastName: null,
  avatar: null,
  phone: null,
  email: null,
  company: null,
  companyId: null,
  // about: null,
  // skills: null,
  dateStartWorking: null,
  dateOfBirth: null,
  isPermissionAccessUsers: null,
  isPermissionEditUsers: null,
  isActive: null,
  userType: null,
  newslettersUserType: null,
  titlesList: [],
};

const state = {
  employees: null,
  employee: DEFAULT_EMPLOYEE,
  employeesPage: {
    count: 1,
    page_size: 11,
  },
  employeeError: null,
  companyList: [],
  skillList: [],
  titlesList: [],
};

const getters = {
  employeeList: (state) => state.employees,
  titlesList: (state) => state.titlesList,
  employeeItem: (state) => state.employee,
  employeeItemEdit: (state) => {
    const putVal = { ...state.employee };
    delete putVal.fullName;
    delete putVal.company;
    delete putVal.pk;
    // delete putVal.skills;
    putVal.companyIds = state.employee?.companies?.map((el) => el.id);
    return putVal;
  },
  employeesPage: (state) => state.employeesPage,
  employeeError: (state) => state.employeeError,
  companyList: (state, _, rootState) => {
    if (!rootState.user.userInfo.isAdmin) {
      return rootState.user.userInfo.companies;
    }
    console.log(rootState.user.userInfo, rootState.user.userInfo.type === COMPANY_USER);
    return state.companyList;
  },
  skillList: (state) => state.skillList,
};

const mutations = {
  [mutationTypes.EMPLOYEE_LIST](state, payloads) {
    state.employees = payloads.results.map((item) => formatProfile(item));
    state.employeesPage = {
      count: payloads.count,
      page_size: payloads.page_size,
    };
  },
  [mutationTypes.EMPLOYEE_ITEM](state, employeeInfo) {
    if (employeeInfo) {
      state.employee = formatProfile(employeeInfo);
    } else {
      state.employee = null;
    }
  },
  [mutationTypes.EMPLOYEE_ERROR](state, errorMsg) {
    state.employeeError = errorMsg;
  },
  [mutationTypes.EMPLOYEE_COMPANY_LIST](state, companyList) {
    state.companyList = companyList;
  },
  [mutationTypes.EMPLOYEE_SKILL_LIST](state, skillList) {
    state.skillList = skillList;
  },
  [mutationTypes.EMPLOYEE_TITLES_LIST](state, titlesList) {
    state.titlesList = titlesList;
  },
  [mutationTypes.EMPLOYEE_DESTROY_ITEM](state) {
    state.employee = DEFAULT_EMPLOYEE;
  },
};

const actions = {
  async [actionTypes.GET_EMPLOYEE_ITEM]({ commit }, pk) {
    commit(mutationTypes.EMPLOYEE_DESTROY_ITEM);
    try {
      const employeeInfo = await EmployeeRepository.getEmployeeItem(pk);
      commit(mutationTypes.EMPLOYEE_ITEM, employeeInfo);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.GET_EMPLOYEE_LIST]({ commit }, payloads) {
    try {
      const userInfo = await EmployeeRepository.getEmployeeList(payloads);
      commit(mutationTypes.EMPLOYEE_LIST, userInfo);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.GET_EMPLOYEE_COMPANY_LIST]({ commit }) {
    try {
      const companyList = await EmployeeRepository.getCompanyList();
      commit(mutationTypes.EMPLOYEE_COMPANY_LIST, companyList);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.GET_EMPLOYEE_SKILL_LIST]({ commit }) {
    try {
      const skillList = await EmployeeRepository.getSkillList();
      commit(mutationTypes.EMPLOYEE_SKILL_LIST, skillList);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.GET_EMPLOYEE_TITLES_LIST]({ commit }) {
    try {
      const titleList = await EmployeeRepository.getTitlesList();
      commit(mutationTypes.EMPLOYEE_TITLES_LIST, titleList);
      return true;
    } catch (e) {
      console.log(e);
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.CREATE_EMPLOYEE_TITLE]({ commit }, payloads) {
    try {
      await EmployeeRepository.createEmployeeTitle(payloads);
      return true;
    } catch (e) {
      console.log(e);
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },

  async [actionTypes.CREATE_EMPLOYEE]({ commit }, payloads) {
    try {
      const userInfo = await EmployeeRepository.createEmployee(payloads);
      commit(mutationTypes.EMPLOYEE_ITEM, userInfo);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.EDIT_EMPLOYEE]({ commit }, payloads) {
    try {
      const userInfo = await EmployeeRepository.editEmployee(payloads.pk, payloads.data);
      commit(mutationTypes.EMPLOYEE_ITEM, userInfo);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },
  async [actionTypes.SET_PASSWORD]({ commit }, payloads) {
    try {
      await EmployeeRepository.setPassword(payloads.pk, payloads.data);
      return true;
    } catch (e) {
      if (e instanceof BaseRepositoryError) {
        commit(mutationTypes.EMPLOYEE_ERROR, e.message);
      }
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
