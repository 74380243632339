import { WEB_ADMIN, COMPANY_USER } from '../configs';
// eslint-disable-next-line import/prefer-default-export
export const formatProfile = (item) => ({
  pk: item.pk,
  fullName: `${item.first_name} ${item.last_name}`,
  firstName: item.first_name,
  lastName: item.last_name,
  title: item.title,
  titleObj: item.title_obj,
  avatar: item.avatar,
  phone: item.phone ? item.phone : '',
  cellPhone: item.cell_phone ? item.cell_phone : '',
  linkedinUrl: item.linkedin_url,
  // about: item.about,
  email: item.email,
  company: item.company ? item.company.name : null,
  companies: item.companies,
  companyId: item.company ? item.company.id : null,
  userCompanyId: item.company ? item.company.id : null,
  // userCurrency: item.company.currency ? item.company.currency : null,
  // skills: item.skills,
  dateStartWorking: item.date_start_working,
  dateOfBirth: item.date_of_birth,
  isPermissionAccessUsers: item.is_permission_access_users,
  isPermissionEditUsers: item.is_permission_edit_users,
  isActive: item.is_active,
  userType: item.type,
  type: item.type,
  newslettersUserType: item.newsletters_user_type,
  newslettersVisibility: item.newsletters_visibility,
  employeeVisibility: item.employee_visibility,
  isAdmin: item.type === WEB_ADMIN,
  isCompany: item.type === COMPANY_USER,
});

// eslint-disable-next-line import/prefer-default-export
export const formatEditedProfile = (item) => {
  const putVal = { ...item };
  delete putVal.fullName;
  // delete putVal.skills;
  delete putVal.company;
  delete putVal.pk;
  delete putVal.isAdmin;
  delete putVal.isCompany;
  delete putVal.userType;
  delete putVal.type;
  delete putVal.newsletters_user_type;
  delete putVal.newsletters_visibility;
  delete putVal.employee_visibility;
  // delete putVal.userCurrency;
  // delete putVal.newslettersUserType;
  // putVal.skillIds = item.skills.map((id) => id);
  return putVal;
};
